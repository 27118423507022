html,
body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden; /* 추가적인 보조 조치 */
  overflow-y: auto; /* 세로 스크롤 허용 */
  height: 100dvh; /* 전체 페이지 높이를 100%로 설정 */
}

#workdetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-items: center;
  height: 225px;
}

#workdetails * {
  justify-content: center; /* 수평 가운데 정렬 추가 */
  text-align: center;
  color: #412823;
  margin-top: 10px;
}

#worktitle {
  font-family: "ClimateCrisisKR-1979";
  font-size: 17px;
  letter-spacing: -0.025em;
  line-height: 18pt;
  margin-bottom: -5px;
  width: 100%;
}


#workdate {
  font-family: "Pretendard-Regular", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8pt;
  letter-spacing: -0.025em;
  margin-bottom: -2px;
}

#workexplanation {
  width: calc(100%);
  font-family: "Pretendard-Regular", sans-serif;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: -0.025em;
  text-align: justify;
  width: calc(100% - 20px);
}

#scrolldownbutton {
  width: 170px;
  height: 35px;
  background: #f8f6f1;
  border: 1px solid #e6e1dc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  font-family: "Pretendard-Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.025em;
  color: #412823;
  cursor: pointer; /* 마우스 포인터 변경 */
}

#scrollupbutton {
  margin-top: 10px;
  width: 170px;
  height: 35px;
  background: #f8f6f1;
  border: 1px solid #e6e1dc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

  font-family: "Pretendard-Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.025em;
  color: #412823;
  cursor: pointer; /* 마우스 포인터 변경 */
}
s button:active {
  background: #e9a7a7 !important; /* 배경색 변경 */
  color: #e6e1dc !important; /* 글씨색 변경 */
  border-color: #e6e1dc !important; /* 테두리 색 변경 */
}
