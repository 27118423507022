.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  font-size: calc(10px + 2vmin);
  color: #f8f6f1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  user-select: none;
  margin: 0;
  padding: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f6f1;
  overflow: hidden;

  font-family: "Pretandard-Regular", sans-serif;
}

::-webkit-scrollbar {
  scrollbar-width: none;
  display: none;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.fixed-size-container {
  width: 390px;
  height: 780px;
  background-color: #f8f6f1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow: auto;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

@media (min-width: 500px) and (min-height: 500px) {
  .fixed-size-container {
    transform: translate(-50%, -50%) scale(var(calc(1 / --scale-responsive), 1));
  }
}

@media (min-width: 500px) and (min-height: 500px) and (orientation: portrait) {
  .fixed-size-container {
    transform: scale(calc(390 / 100vw));
  }
}

.size-info-container {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #f8f6f1;
  z-index: 999999;
  /* transform: translate(, 0); */
}

.size-info-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

button {
  vertical-align: center;
}
